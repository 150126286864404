<template>

  <div>
    <b-card>
      <header_pencarian> </header_pencarian>
    </b-card>
    <b-card>
      <tabel_data></tabel_data>
    </b-card>

  </div>

</template>

<script>
import {
  BTabs,
  BTab, BCard, BRow, BCol
} from 'bootstrap-vue'

import header_pencarian from './component/header_pencarian.vue'
import tabel_data from './component/tabel_data.vue';
export default {
  components: {
    BTabs, BTab, BCard, BRow, BCol, header_pencarian, tabel_data
  }
}
</script>

<style></style>